.simple-dashboard-items{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 0.1rem;   
}
.simple-db-item{
    height: 5rem;
    width: 10rem;
    background-color: white;
    border-radius: 0.3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: 0.5rem;
    margin-bottom: 0.4rem;
}
.dashboard-mb{
    margin-bottom: 0.3rem;
}
.simple-dashbord-title{
    margin-top: 0.1rem;
    margin-bottom: 0;
    color: #312f2f;
    font-weight: 500;
    font-size: 0.9rem;
}
.charts-view{
    height: 58vh;
    display: flex;
    align-items: center;
    justify-content: center;
    justify-content: space-between;
}
.charts-view-item{
width: 32rem;
height: 95%;
background-color: white;
border-radius: 0.5rem;
display: flex;
align-items: center;
flex-direction: column;
justify-content: center;
}

.dashboad-menu{
    display: flex;
    margin-bottom: 0.5rem;
}
.btn-toggle{
    padding: 0.5rem 1rem;
    background-color: rgb(6, 182, 6);
    border: none;
    color: white;
    border-radius: 0.3rem;
    margin-right: 1rem;
  }
  .chart-title{
    margin-top: 0.1rem;
    margin-bottom: 0.5rem;
    color: #606060;
    font-weight: 500;
    font-size: 0.9rem;
}
.pie-chart{
    width: 95%;
}