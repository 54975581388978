.top-menu{
    background-color: white;
    width: 96%;
    border-radius: 0.5rem;
    height: 4.5rem;
    padding: 1rem;
    margin-top: 0.5rem;
    display: flex;
    justify-content: space-between;
}
.menu-item{
height: 100%;
display: flex;
flex-direction: column;
align-items: flex-start;
justify-content: center;
}
.item-width-order{
    width: 7rem;
}
.item-width-shop{
    width: 10rem;
}
.menu-title{
    font-size: 0.8rem;
    font-weight: 500;
    margin: 0;
    color: rgb(6, 182, 6);
}

.menu-content{
    font-size: 0.8rem;
    color: #535252;
    font-weight: 500;
}
.top-header{
    display: flex;
    justify-content: space-between;
}
.header-item{
    display: flex;
    justify-content: flex-start;  
    flex-wrap: wrap;
}
.mr-t{
margin-top: 0.9rem;
}
.mt-r{
    margin-right: 0.5rem;
}
.products-items{
    max-height: 95%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.product-item{
    display: flex;
    justify-content: flex-start;
    width:30rem;
    height: 12rem;
    margin-bottom: 0.8rem;
    border: green solid 0.03rem;
    background-color: white;
    border-radius: 0.4rem 0.4rem 0.4rem 0.5rem;
}
.product-img{
    width:12rem;
    height: 12rem;
}
.product-head{
width: 12rem;
}
.product-details{
width: 100%;
height: 100%;
display: flex;
flex-direction: column;
justify-items: end;
padding-right: 0.2rem;
}
.product-action{
margin-top: auto;
margin-bottom: auto;
}
.product-title{
    font-weight: 500;
    font-size: 0.9rem;
    color: #242323;
}
.contents-description{
    font-size: 0.8rem;
    font-weight: 400;
    margin: 0;
    color: #242323;
}
.product-id{
    font-weight: 700;
    font-size: 0.9rem;
    color: rgb(6, 182, 6);
    margin-bottom: 0.2;
    margin-top: 0.1rem;
}
.rev-title{
    font-weight: 500;
    font-size: 0.8rem;
    color: #464646;
    margin-top: 0.8rem;
    margin-bottom: 0.4rem;
}
.review-header{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    background-color: white;
    height: 6rem;
    border-radius: 0.5rem;
    margin-top: 0.3rem;
}

.rev-hd-cont{
    width:10rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;   
}
.rev-header-item{
    font-weight: 500;
    font-size: 0.8rem;
    color: #039f1a;
    margin-bottom: 0;
    margin-top: 0.2rem;
}
.review-content-bd{
    background-color: white;
    width:30rem;
    padding: 0.5rem;
    border-radius: 0.5rem;
}
.order-menu{
display: flex;
}

.map-wrapper{
    height: 100vh;
    width: 100vw;
}
.map-component{
    height: 100%;
    width: 100%;
}
.dash-item{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.order-dash-mrl{
    margin-left: 2rem;
}
.filter-dash-mr{
    margin-left: 0.5rem;
}
.filter-dash-mr-1{
    margin-left: 0.2rem;
}
.icon-holder{
    background-color: white;
    border-radius: 100%;
    height: 1.5rem;
    width: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
}
.icons-mr-r1{
    margin-right: 1.5rem;
    margin-left: 0.5rem;
}
.icon-mr-l1{
    margin-left: 0.4rem;
    margin-top: 0.3rem;
}

.order-tb-btn{
    background-color: white;
    margin-left: 0.5rem;
    border-radius: 0.2rem;
    height: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding:0.1rem 0.8rem;
    font-size: 0.8rem;;
}
.tab-orde-txt{
    margin-right: 0.8rem;
}
.tab-order-active{
    color: #03d723;
}
.customer-sp{
    margin-left: 1rem;
}