.login2-main{
    width: 100%;
    min-height: 100vh;
    min-width: 740px;
    margin-right: auto;
    margin-left: auto;
    display: flex;
}
.img-body{
    width: 100vh;
    height:100vh;
    display: block;
}
.lg-pt-1{
    width: 50%;
    max-height: 100vh;

    background-image: url(../image/bg_0.png) ;
    background-size: cover;
}
.slogan-txt{
    color: white;
    font-weight: 700;
    font-size: 2rem;
    letter-spacing: 0.2px;
}
.slogan-body{
width: 70%;
margin-top: 18%;
margin-left: 3rem;
}
.lg-pt-2{
    width: 50%;
    /* width: 50%;
    min-height: 100vh;
    background-color: purple; */
}
.login-form-bd{
    width: 60%;
    min-height: 95vh;
    margin-right: auto;
    margin-left: auto;
    margin-top: 0;
}
.logo2-convas{
    width: 100%;
    display: flex;
    justify-content: center;
    /*  justify-content: flex-end; */
}
.logo2{
    width: 8rem;
    height: 8rem;
    margin-left: 1rem;
    margin-top: 0;
}
.logo-div-1{
    margin-top: 2rem;
}
.form-login-2{
    background-color: white;
    padding: 0.5rem 0.5rem 2.5rem 0.5rem;
    border-radius: 0.4rem;
}
.form-login-1{
    background-color: white;
    padding: 0.5rem 0.5rem 0.8rem 0.5rem;
    border-radius: 0.4rem;
}
.salutation{
    font-size: 1.2;
    font-weight: 500;
    color: rgb(27, 26, 26);
    margin-top: 0rem;
    margin-bottom: 1rem;
}
.sign-in{
    margin-top: 0rem;
    margin-bottom: 0rem;
    margin-right: 0;
    margin-left: 0;
    font-weight: 500;
    font-size: 1rem;
    color: rgb(27, 26, 26);    
    letter-spacing: 0;
}

.input-log2{
    width: 100%;
    height: 2rem;
    border: 1.2px solid #a9a8a8;
    border-radius: 0.3rem;
    font-size: 0.8rem;
    color: #6c6b6b;   
    margin-top: 0.8rem;
}
.input-log2:focus{
    outline: 0rem;
}
.log2-div{
width: 90%;
margin-left: auto;
margin-right: auto;
}

.signin-btn{
    padding: 0.6rem 1rem;
    background-color: #004466;
    border: none;
    color: white;
    border-radius: 0.2rem;
    width: 102%;
    margin-bottom: 0.8rem;
}
.forgot-password{
width: 90%;
margin-left: auto;
margin-right: auto;
display: flex;
justify-content: flex-end;
}
.forgot-password-sps{
    margin-top: 0.4rem;
    margin-bottom: 0rem;
    margin-right: 0.5rem;
    margin-left: 0;
    font-size: 0.7rem;
    font-weight: 500;
    color:#1f1e1e;
    display: inline-block;
    
}
.reset-passsword{
    margin-top: 0.4rem;
    margin-bottom: 0rem;
    margin-right: 0.5rem;
    margin-left: 0.1rem;
    color:#004466;
    font-weight: 400;
    font-size: 0.7rem;
    display: inline-block;
}
.reset-passsword:hover{
    text-decoration: underline;
}
.greet-emoji{
    font-size:1rem;
    margin-left:0.5rem;
}