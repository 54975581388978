.form-body{
    width: 60%;
    margin-right: auto;
    
}
.input-item{
margin-top: 0.8rem;
}
.input-right{
    text-align:right;
}
.input-value{
    border: 0.5px solid;
    border-radius: 0.2rem;
    font-size: 1rem;
    color: #4d4d4d;  
    width: 90%;
    height: 2.2rem; 
    margin-top: 0.2rem;
}
.input-value:focus{
    outline: 0rem;
    border: 0.5px solid;
    width: 90%;
    height: 2.2rem;
    border-radius: 0.2rem;
}
.title-mg{
    margin-top: 0.5rem;
    margin-bottom: 0.2rem;
}
.form-btn{
    padding: 0.6rem 1rem;
    background-color: rgb(6, 182, 6);
    border: none;
    color: white;
    border-radius: 0.2rem;
    margin-top: 0.5rem;
    font-weight: 400; 
}
.input-txt{
    font-size: 1rem;
    font-weight: 400;  
    color: #090909;
}

.search-orders{
    border: 0.2px solid;
    border-radius: 0.5rem;
    font-size: 1rem;
    color: #4d4d4d;  
    width: 95%;
    height: 1.8rem; 
    margin-top: 0.1rem;
    margin-right: 0.1rem;
}
.search-orders,.search-feedback:focus{
    outline: 0rem;
}
.user-form-create{
    width: 80%;
    /* min-height: 100vh; */
    margin-left: auto;
    margin-right: auto;  
}


.input-pair-item{
    width: 100%;
    display: flex;
    justify-content: space-between;
    

}
.user-input-dv{
    margin-top: 0.8rem;
    width: 50%;
}
.sn-item-input{
    margin-top: 0.8rem;
    width: 50%;
    }
    .search-feedback{
        border: 0.2px solid;
        border-radius: 0.2rem;
        font-size: 1rem;
        color: #4d4d4d;  
        width: 95%;
        height: 1.8rem; 
        margin-top: 0.1rem;
        margin-right: 0.1rem;
    }
    .bg-col{
        background-color: green;
    }
    .filter-order{
        border: 0.2px solid;
        border-radius: 0.5rem;
        font-size: 0.8rem;
        color: #4d4d4d;  
        width: 5.8rem;
        height: 1.8rem; 
        margin-top: 0.1rem;
        margin-right: 0.1rem;
    }
    .filter-lb{
        display: inline-block;
        color: white;
        margin-right: 0.2rem;
        margin-left: 0rem;
    }
    .filter-order:focus{
        outline: 0rem;
    }
    .card-form{
        background-color: white;
        width: 88%;
        border-radius: 0.5rem;
        padding: 0.5rem;
        margin-bottom: 0.5rem;
    }