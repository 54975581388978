.pop-dial{
    width: 95%;
    background-color: rgb(251, 255, 251);
    padding: 0.5rem;
    border-radius: 0.3rem;
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
}
.alert-title{
  margin: 0;
  font-weight: 400;
  font-size: 1rem;
}
.alert-cancel{
    margin: 0;
    font-weight: 400;
    font-size: 1.2rem;
}
.alert-cl{
    color: rgb(10, 246, 10);
}
.alert-error{
    color: rgb(191, 7, 7);
}
