
.home-main{
  min-height: 100vh;
  display: grid;
  grid-template-columns: 2fr 10fr;
  grid-template-rows: 1fr;
}
.side-section{
  width: 98%;
  background-color: white;
}
.main-section{
  max-height: 100vh;
  /* overflow-y: hidden; */
}
.mr-1{
  margin-left: 0.9rem;
}

.non-mr{ 
  margin: 0;
}
.link a{
  text-decoration: none;
  color: #4d4d4d;
  font-size: 0.8rem;
  font-weight: 400;
}
.sub-menu{
  margin-top: 0.1rem;
  margin-left: 0.2rem;
  /* background-color: pink; */
}
.menu-url{
  margin-top: 0;
  margin-bottom: 0.2rem;
  margin-right: 0;
  margin-left: 1rem;
}
.side-menu-title{
font-size: 0.9rem;
font-weight: 500;
color:rgb(6, 182, 6);
}
.sub-menu-title{
  margin-top: 0;
  margin-bottom: 0.5rem;
  margin-right: 0;
  margin-left: 0;
  font-weight: 400;
  font-size: 0.9rem;
}
.sub-mn-title{
  margin-top: 0;
  margin-bottom: 0.5rem;
  margin-right: 0;
  margin-left: 0;
}
.sub-menu-icon{
  margin-top: 0;
  margin-bottom: 0.1rem;
  margin-right: 1rem;
  margin-left: 0;
  font-weight: 900;
  font-size: 0.9rem;
  padding: 0;
}
.menu-sps{
  margin-top: 0;
  margin-bottom: 0.5rem;
  margin-right: 0;
  margin-left: 0rem;
}
.logo-img{
  width: 5rem;
  height: 5rem;
  margin-left: 1rem;
}
.p-name{
  font-weight: 700;
  font-size: 1.3rem;
  color: rgb(6, 182, 6);
}
.navigation{
  height: 6vh;
  background-color: green;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
@media screen and (max-width: 690px){
  .navigation{
    min-width: 685px;
    justify-content: center;
  }
}
.nav-items{
margin-right: 2rem;
}
.nav-menus{
  margin-top: 0rem;
list-style-type: none;
margin-bottom: 0rem;

}
.nav-menus li{
  display: inline;
  margin-left: 3rem;
}
.btn{
  padding: 0.5rem;
  background-color: rgb(6, 182, 6);
  border: none;
  color: white;
  border-radius: 0.3rem;
}
.user-name{
  color:white;
  font-weight: 400;
  font-size: 0.9rem;
}
.home-link a{
  text-decoration: none;
  color: rgb(245, 249, 245);
  font-weight: 400;
  font-size: 1rem;
}
.brand-title{
  display: flex;
}
.nav-deco{
  color: rgb(6, 182, 6);
}
.arrow{
  margin-left: 2rem;
  padding-top: 1rem;
}
.menu-icon-spacer{
  margin-right: 0.5rem;
}
.active-order{
  display: inline;
  }
  .inactive-order{
      display: none;
  }
  .locator{
    color: rgb(6, 182, 6);
    font-weight: 400;
  }
  .locator:hover{
    color: rgb(4, 142, 4);
    font-weight: 500;
    text-decoration: underline;
  }

  .item-sub{
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.1rem;
  }
  .body-page{
    width: 99%;
    max-height: 85vh; 
    margin-right: auto;
    margin-left: auto;  
    overflow-y: auto;
 
  }
  .top-strip-bg{
    height: 6vh;
    width: 100%;
    background-color: rgb(26, 31, 58);
    display: flex;
    justify-content: center;
    align-items: center;
    /* box-shadow: 0px 10px #faf7f7; rgb(26, 31, 58)   rgb(14, 16, 27)//Darker*/
   
  }

  .top-portal-title{
    margin: 0rem;
    color: white;
    font-weight: 500;
    font-size: 0.9rem;
    margin-right: 1rem;
    
  }
  .dashboard-spacers{
    margin-top: 0.5rem;
    margin-bottom: 0.2rem;
    margin-left: 0.5rem;
  }
.space-HI{
  margin-left: 0.2rem;
}
.mr-rt-2{
  margin-right: 3rem;
 
}
/* select{
 option:hover {outline:0 !important;}
} */



  /* select option:hover {
    box-shadow: 0 0 10px 100px red inset !important;
    color:brown;
  } */
