.main-content{
    width: 100%;
    max-height: 83vh; 
}
.home-page{
    max-height: 85vh;
    width:98%;
    margin-left: auto;
    margin-right: auto;
    overflow-y: auto;
}
.inner-home{
    /* background-color: black; */
    height: 90vh;
}
.dashbord-title{
    margin-top: 1rem;
    margin-bottom: 0;
    color: #312f2f;
    font-weight: 500;
    font-size: 0.9rem;
}
.dashboard-items{
    height: 8rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem
}
.db-item{
    height: 7rem;
    width: 10rem;
    background-color: white;
    border-radius: 0.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: 0.5rem;
}
.value-items{
    margin: 0.4rem 0rem 0rem 0rem;
    font-weight: 500;
    font-size: 1rem;
    color:rgb(6, 182, 6);
}
.title-item{
    font-weight: 700;
    font-size: 0.8rem;
    color: #4d4d4d;
    font-weight: 500;
}
.dashboard-users{
    width:25rem;
}
.actions-tb{
    display: flex;
    justify-content: space-between;
    margin-top: 0.1rem;
    margin-bottom: 0.4rem;
    /* background-color: rgb(14, 16, 27); */
}
.actions-tb-en{
    display: flex;
    justify-content: space-between;
    /*  */
}
.action-height{
    height: 8vh;
}
.dashboard-bg{
    background-color: rgb(14, 16, 27);
}
.dashboard-bg-orders{
    background-color: rgb(22, 27, 54);
}

.dt-actions{
    display: flex;
    justify-content: space-between;
    height: 8vh;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
}

.tb-title{
    font-weight: 500;
    font-size: 0.9rem;
    color: rgb(62, 61, 61);
}
.tb-dashboard{
    font-weight: 500;
    font-size: 0.9rem;
    color: white;  
    margin-left: 0.5rem;
}
.action-btn{
    padding: 0.5rem 0.7rem;
    background-color: rgb(6, 182, 6);
    border: none;
    color: white;
    border-radius: 0.3rem;
    
}
.tb-btn-stl{
    padding: 0.3rem 0.4rem;
    background-color: white;
    color: rgb(14, 16, 27);
    border-radius: 0.2rem;
    border: solid rgb(14, 16, 27) 1px;
}
.tb-btn-stl-bolder{
    padding: 0.3rem 0.4rem;
    background-color: white;
    color:rgb(6, 182, 6);
    border-radius: 0.2rem;
    border: solid rgb(3, 206, 3) 1.5px;
}


.menu-locate{
    margin-top:0.4rem;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
}
.tb-btns{
    padding: 0.3rem 0.4rem;
    background-color: rgb(6, 182, 6);
    border: none;
    color: white;
    border-radius: 0.2rem;
}
.pricing-menu{
    overflow-y: auto;
}

