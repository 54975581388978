.login-main{
        width: 45%;
        min-height: 100vh;
        margin-right: auto;
        margin-left: auto;
        display: flex;
        
}
.middle-body{
    max-height: 50vh;
    width: 100%;
    min-width: 32rem;
    
}
.login-form{
   background-color: white;
   display: flex;
   flex-direction: column;
   align-items: center;
   padding: 0.4rem;
   border-radius: 0.5rem;
   box-shadow: 0.5rem 0.5rem 0.5rem #c0c0c0;
   min-width: 30rem;
   
}
.input-dv{
    width: 95%;
    margin-bottom: 0.8rem;
}
.title-div{
    margin-bottom: 0.8rem;
    margin-top: 0.5rem;
    align-self: center;
    width: 95%;
}


.input-fv{
    width: 100%;
    height: 2.2rem;
    border: 1.2px solid #a9a8a8;
    border-radius: 0.2rem;
    font-size: 1.2rem;
    color: #6c6b6b;   
    margin: 0;
}
.input-fv:focus{
    outline: 0rem;
    border: 0.5px solid;
    width: 100%;
    height: 2.2rem;
    border-radius: 0.2rem;
}
.login-btn{
    padding: 0.6rem 1rem;
    background-color: rgb(6, 182, 6);
    border: none;
    color: white;
    border-radius: 0.2rem;
}
.login-title{
    margin-top: 0rem;
    margin-bottom: 0rem;
    margin-right: 0;
    margin-left: 0;
    font-weight: 500;
    font-size: 1.2rem;
    color: rgb(6, 182, 6);   
}
.form-label{
    font-size: 1rem;
    color: #4d4d4d; 
    font-weight: 400;  
    display: block;
}
.error{
    margin: 0;
    color: red;
    font-size: 1rem;
    font-style: italic;
    font-weight: 400;  
}
.top-head{
    margin-top: 1rem;
}
.logo-login-main{
    width: 8rem;
    height: 8rem;
    margin-left: 1rem;
}
.pop{
width: 100%;
background-color: white;
border-radius: 0.3rem;
display: flex;
justify-content: space-between;
margin-bottom: 0.8rem;
/* padding: 0.4rem; */
}
.iserror{
    display: none;
}
.pop-item p{
 margin: 7px;
}
.error-color{
 color: rgb(251, 77, 77);
}
.error-txt{
    font-weight: 500;
    font-size: 0.8rem;
}